import './IntakeForm.css';
import Button from '../Button/Button.js';
import DropDownMenu from '../DropDownMenu/DropDownMenu.js';
import CheckboxGroup from '../CheckBoxGroup/CheckBoxGroup.js';
import DateTimeSelect from '../DateTimeSelect/DateTimeSelect.js';

const speciesOptions = ["Cat", "Dog", "Other"];
const breedOptions = ["", "Domestic Longhair", "Domestic Mediumhair", "Domestic Shorthair", "Maine Coon", "Siamese", "Other"];
const catTypes = ["", "House Cat", "Feral", "Barn Cat", "TNR", "Other"]
const colorOptions = ["", "Black", "Blue", "Blue Cream", "Brown", "Calico", "Chocolate", "Cinnamon", "Cream", "Fawn", "Gray", "Lilac", "Orange", "Piebald", "Silver", "Smoke", "Tabby", "Tortoiseshell", "Tuxedo", "Other"];
const intakeOptions = ["", "Found", "Surrendered", "Transfer", "Other"];
const tabbyTypeOptions = ["", "Classic Tabby", "Mackerel Tabby", "Patched Tabby (Torbie)", "Spotted Tabby", "Ticked Tabby"];
const estimatedAgeOptions = ["", "Kitten", "Young Adult", "Adult", "Senior"];
const sexOptions = ["", "Male", "Female", "Unknown"];
const expectedWeightOptions = Array.from({length: 31}, (_, i) => i); // Creates array with numbers from 0 to 30.
const sizeOptions = ["", "Small", "Medium", "Large"];
const vaccinationOptions = ["Rabies", "FVRCP (Feline Viral Rhinotracheitis, Calicivirus, Panleukopenia)", "FeLV (Feline Leukemia Virus)", "FIP (Feline Infectious Peritonitis)", "Other (please specify)"];
const treatmentOptions = ["Flea Prevention", "Tick Prevention", "Deworming", "Heartworm Prevention", "Ear Mite Treatment", "FIV/FeLV Testing", "Other (please specify)"];
const dietaryNeedsOptions = [
  "Prescription Diet",
  "Allergies (please specify)",
  "Weight Management",
  "Age-Specific Diet (Kitten, Senior, etc. please specify)",
  "Other Dietary Restrictions (please specify)",
];

const medicalNeedsOptions = [
  "Chronic Illness (please specify)",
  "Mobility Issues",
  "Regular Medication (please specify)",
  "Post-Surgical Care Requirements",
  "Vision Impairment",
  "Hearing Impairment",
  "Requires Regular Veterinary Check-ups (please specify)",
  "Other Medical Conditions (please specify)",
];

const behavioralEmotionalNeedsOptions = [
  "Anxiety Management",
  "Special Training Requirements",
  "Needs a Quiet Environment",
  "Not Suitable for Homes with Small Children",
  "Not Suitable for Homes with Other Pets",
  "Other Behavioral Needs (please specify)",
];

const otherSpecialNeedsOptions = [
  "Grooming Requirements",
  "Environmental Adjustments",
  "Frequent Monitoring or Special Attention Required",
  "Other (please specify)",
];
// Array for Behavioral Traits
const behavioralObservationsOptions = [
  "Friendly",
  "Shy",
  "Aggressive",
  "Good with Children",
  "Good with Other Animals",
  "Playful (please specify)",
  "Laid Back",
  "Independent",
  "Vocal",
  "Nervous / Anxious",
  "Curious",
  "Affectionate",
  "Scratcher (Furniture, etc.)",
  "Litter-Trained",
  "Special Needs (e.g., dietary, medical)",
  "Leash-Trained (for walks)",
  "Likes to be Held / Lap Cat",
  "Territorial",
  "Enjoys Outdoors",
  "Prefers Solitude",
  "Has a History of Escaping",
  "Interacts Well with Other Cats",
  "Interacts Well with Dogs"
];

const IntakeFormComponent = () => {
  return (
    <div className="IntakeForm">
      <form>
        <DateTimeSelect />
        <br />
        <h3>Animal Information:</h3>
        <DropDownMenu options={speciesOptions} label="Species" />
        <DropDownMenu options={breedOptions} label="Breed" required={true} />
        <DropDownMenu options={catTypes} label="Type" required={true} />
        <DropDownMenu options={colorOptions} label="Color" required={true} />
        <DropDownMenu options={sexOptions} label="Sex" required={true} />
        <DropDownMenu options={tabbyTypeOptions} label="Tabby Type (if applicable)" required={false} />
        <DropDownMenu options={estimatedAgeOptions} label="Estimated Age" required={false} />
        <DropDownMenu options={expectedWeightOptions} label="Expected Weight" required={false} />
        <DropDownMenu options={sizeOptions} label="Size" required={false} />
        <label>Distinguishing Feature:</label><input type="text"/><br/>
        {/* <RadioButtonGroup label="Ear Tipped?" /> */}
        <DropDownMenu options={["", "Yes", "No"]} label="Ear Tipped?" required={false} />
        <br />

        <h3>Circumstances of Intake</h3>
        <DropDownMenu options={intakeOptions} label="Found, Surrendered, Transfer" required={false} />
        <br />
        <h5>Animal's Initial Location</h5>
        { /* Try USPS address verifier; Try Google Maps */}
        <label>General Location Found:</label><input type="text"/><br/>
        { /* Try USPS address verifier; Try Google Maps */}
        <label>Or Specific Address:</label><input type="text"/><br/>

        <h3>Finder/Relinquisher Information:</h3>
        <label>Name of person who alerted you to cat:</label><input type="text"/><br/>
        <h5>Contact info:</h5>
        <label>Email:</label><input type="text"/><br/>
        <label>Phone Number:</label><input type="text"/><br/>
        <label>Address:</label><input type="text"/><br/>

        <h3>Medical and Behavioral Information:</h3>
        <h5>Medical History</h5>
        <CheckboxGroup options={vaccinationOptions} label="Vaccinations:" required={false} />
        <CheckboxGroup options={treatmentOptions} label="Treatments:" required={false} />
        <label>Additional Medical Notes:</label><textarea/><br/>
        <DropDownMenu options={["", "Unknown", "Yes", "No"]} label="Spay/Neutered?" required={true} />
        <label>Illnesses or Injuries:</label><textarea/><br/>
        <h5>Behavioral Observations</h5>
        <CheckboxGroup options={behavioralObservationsOptions} label="Behavioral Observations:" required={false} />
        <label>Initial Assessment:</label><textarea/><br/>
        <br />

        <h3>Special Needs:</h3>
        <CheckboxGroup options={dietaryNeedsOptions} label="Dietary Needs:" required={false} />
        <CheckboxGroup options={medicalNeedsOptions} label="Medical Needs:" required={false} />
        <CheckboxGroup options={behavioralEmotionalNeedsOptions} label="Behavioral / Emotional Needs:" required={false} />
        <CheckboxGroup options={otherSpecialNeedsOptions} label="Other Special Needs:" required={false} />
        <label>Special Needs Details:</label><textarea/><br/>
        <br />

        <h3>Additional Information:</h3>
        <label>Microchip ID:</label><input type="text"/><br/>
        <label>Placement Goals:</label><textarea/><br/>
        <label>Intake Costs:</label><input type="number" min='0' step="1.00" required /><br/>
        <div className="RequiredNumber">
          <span>*Initial Cost is required.</span>
        </div>
        <label>Mileage:</label><input type="number" min='0' required /><br/>
        <div className="RequiredNumber">
          <span>*Mileage is required.</span>
        </div>
        <label>Additional Comments:</label><textarea/><br/>
        <br />
        <Button/>
      </form>
    </div>
  );
}

export default IntakeFormComponent;