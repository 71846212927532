import { React } from 'react';
import './Waystogive.css';
import Linknav from '../Linknav/Linknav';

function Waystogive() {

  return (
    <>
      <div className='ways-to-give wtg-bgimg'>
        <h1>Ways to Give</h1>
        <p>Thank you for helping us help kitties! </p>
        <p>12 Inch Tails depends greatly on the kindness of our donors to offer a secure sanctuary, medical care, and nurturing foster homes for abandoned animals. </p>

        {/* <div className='content'>
          <p>Thank you for helping us help kitties! 12 Inch Tails depends greatly on the kindness of our donors and community support to offer a secure sanctuary, medical care, and nurturing foster homes for abandoned animals. </p>
          <p className='address'>
            Mail a check or gift card to:<br/>
            325 E Jimmie Leeds Rd.<br/>
            STE #7 #186 Galloway, NJ 08205<br/>
            United States
          </p>
        </div> */}
          <Linknav 
            links={[
              { href: "https://buy.stripe.com/4gwbMac0t23M9DaaEF", text: "Credit Card" },
              // { href: "/", text: "Paypal", external: true },
              { href: "https://account.venmo.com/u/TonyaLeann", text: "Venmo", external: true },
              { href: "https://www.amazon.com/hz/wishlist/ls/1AF2KL6LHTS3V?ref_=wl_share", text: "Wish List", external: true }
            ]}
          />
          <p className='address'>
            <strong>Mail a check or gift cards to:</strong><br/>
            <span>325 E Jimmie Leeds Rd.<br/>
            STE #7 #186 Galloway, NJ 08205<br/>
            United States</span>
          </p>
          <p>Make checks payable to <strong>12 Inch Tails</strong></p>
      </div>
    </>
  );
}

export default Waystogive;