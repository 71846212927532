import './Services.css'
import logo from '../../assets/images/transparent_logo.png';
import Linknav from '../Linknav/Linknav';

function Services() {
  return (
    <>
    <div className='services-bgimg'>
      <div id="services" className='services-div'>
        {/* <div className="content-wrapper"> */}
        <h1>Services</h1>

          <p>
          At 12 Inch Tails, our adoration for the animal kingdom knows no bounds. If there's a whisker in distress or a tail in a twist, we're on it faster than a cat on a laser pointer! 
          </p>
          <Linknav 
            links={[
              { href: "/waystogive", text: "Donate" },
              { href: "/foster", text: "Foster" },
              { href: "/adopt", text: "Adopt" },
            ]}
          />
          <p>
          Our true passion? Embarking on daring rescue missions to save our feline friends, giving them a second chance at life in their forever castles. Whether it's playing matchmaker with potential 
          <a className="all-a" href="/adopt"> pet parents</a>, running a feline witness protection program 
          (aka <a className="all-a" href="/foster"> fostering</a>), or operating our top-secret TNR (Trap, Neuter, Return) and TNA (Trap, Neuter, Assess) missions, we're all about those purrs and head bumps.
          </p>
          <p>
          Curious about joining our society of extraordinary cat lovers? 
          Want to know more about the process or how you can become a feline superhero
            by
           <a className="all-a" href="/adopt"> adopting</a> or 
           <a className="all-a" href="/foster"> fostering</a>?
          </p>
          
          <p>
          <a className="all-a" href="/contact">Drop us a line</a>, and let's chat whiskers and paws. Thank you for considering joining the cause of cuddles and meows!
          </p>
          <p>
          <img src={logo} style={{ width: '300px', height: '300px'}} alt="logo" />
          </p>
          </div>
      </div>
    </>
  )
}

export default Services;
