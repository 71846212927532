import { React, useState } from 'react';
import axios from 'axios';
import './Contact.css';
import Button from '../Button/Button';

function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      name: name,
      email: email,
      phone: phone,
      message: message
    };

    await axios.post('https://p0vez52m8i.execute-api.us-east-1.amazonaws.com/prod/kklEmailForwarder', payload)
      .then((res) => {
        console.log(res.data);
        setFormSubmitted(true);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  if(formSubmitted){
    return (
      <div className='new-page'>
        <h1>Thank you for your submission.</h1>
        <p>We will be getting back to you shortly.</p>
      </div>
    )
  } else {
    return (
      <div className='new-page'>
        <h1>Contact Us</h1>
        {/* <p>Please fill out the form below to volunteer, foster, adopt, or any other inquiry.</p> */}
        <form onSubmit={handleFormSubmit} className='contact-form'>
          <input type="text" value={name} onChange={e => setName(e.target.value)} placeholder="Name*" required/>
          <input type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="Email*" required/>
          <input type="tel" value={phone} onChange={e => setPhone(e.target.value)} placeholder="Phone"/>
          <textarea value={message} onChange={e => setMessage(e.target.value)} placeholder="Message*" required></textarea>
          {/* <button type="submit">Send</button> */}
          <Button buttonText="Send" color="#069555"/>
        </form>
      </div>
    );
  }
}

export default Contact;