import './Footer.css'
// import Socialmedia from '../Socailmedia/Socialmedia';

function Footer() {
  return (
    <>
    <footer>
      <p>
        <b>KKL Rehoming LLC @2023 - A New Jersey Nonprofit Corporation - Dba: 12 Inch Tails - All Rights Reserved</b>
      </p>
    </footer>

    </>
  )
}

export default Footer;