import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import {jwtDecode} from "jwt-decode";

function GoogleLoginButton() {
  
  const navigate = useNavigate();

  const responseGoogle = (response) => {
    console.log(response);
    const userObject = jwtDecode(response.credential);
    //console.log(userObject);
    localStorage.setItem('user', JSON.stringify(userObject));
    const { name, sub, picture } = userObject;
    const doc = {
      _id: sub,
      _type: 'user',
      userName: name,
      image: picture,
    };
    console.log(doc);
    console.log(localStorage.getItem('user'));
    // client.createIfNotExists(doc).then(() => {
    //   navigate('/', { replace: true });
    // });
    navigate('/home');
  }

  return (
    <>
    <div className="">
          <div className="">
            <GoogleOAuthProvider 
                clientId='121898622014-emprlfi0oe6vn4baferttrrvmd98rvaa.apps.googleusercontent.com'
                >
             <GoogleLogin
              render={(renderProps) => (
                <button
                  type="button"
                  className=""
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                >Sign in with google
                </button>
              )}
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              //cookiePolicy="single_host_origin"
              cookiePolicy="none"
            />
            </GoogleOAuthProvider>
          </div>
    </div>
    </>
  );
}

export default GoogleLoginButton;