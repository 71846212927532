import { React } from 'react';
import './Followus.css';
import logo from '../../assets/images/transparent_logo.png';
import fb from '../../assets/images/buttons/fb-color.png';
import ig from '../../assets/images/buttons/ig-color.png';
import yt from '../../assets/images/buttons/yt-color2.png';
import tt from '../../assets/images/buttons/tt-color.png';

function Followus() {

  return (
    <>
      <div className='followus followus-bgimg'>
        <h1>Follow us</h1>
        <img src={logo} style={{ width: '340px', height: '340px'}} alt="12inchtails" />
        <p className='social-buttons'>
          <a href="https://www.facebook.com/profile.php?id=61556340613222" target="_blank" rel="noopener noreferrer">
            <img className="social-media-icon" src={fb} style={{ width: '48px', height: '48px'}} alt="facebook" />
          </a>
          <a href="https://www.instagram.com/krazykatlady4u" target="_blank" rel="noopener noreferrer">
            <img className="social-media-icon" src={ig} style={{ width: '50px', height: '50px'}} alt="instagram" />
          </a>
          <a href="https://www.tiktok.com/@12inchtails?fbclid=IwAR2PPUJPxMw1uBFHLNPaVgYR9Fglc_Qyu4iYHYKuqzFG3yoX3Ca425LgVQs" target="_blank" rel="noopener noreferrer">
            <img className="social-media-icon" src={tt} style={{ width: '50px', height: '50px'}} alt="tiktok" />
          </a>
          <a href="https://www.youtube.com/@Katlady4u" target="_blank" rel="noopener noreferrer">
            <img className="social-media-icon" src={yt} style={{ width: '55px', height: '53px'}} alt="youtube" />
          </a>
        </p>
      </div>
    </>
  );
}

export default Followus;