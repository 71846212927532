import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import './DateTimeSelect.css';

const DateTimeSelect = () => {
  const [currentDateTime, setCurrentDateTime] = useState('');

  useEffect(() => {
    const now = moment().tz("America/New_York").format();
    const localDateTime = now.slice(0,16);
    setCurrentDateTime(localDateTime);
  }, []);

  const handleDateChange = (event) => {
    setCurrentDateTime(event.target.value);
  };

  return (
    <div>
        <label>Date/Time of Intake:</label>
        <input 
          type="datetime-local" 
          value={currentDateTime} 
          onChange={handleDateChange} 
          required
        />
    </div>
  );
}

export default DateTimeSelect;
