import React, { useState } from 'react';

const CheckBoxGroup = ({ label, options }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [textInputs, setTextInputs] = useState({});

  const handleOptionChange = (event) => {
    const { value } = event.target;

    setSelectedOptions(prevState =>
      prevState.includes(value)
        ? prevState.filter(option => option !== value)
        : [...prevState, value]
    );
  };

  const handleTextInputChange = (event) => {
    const { name, value } = event.target;
    console.log(textInputs);
    setTextInputs(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  return (
    <div>
      <label>{label}</label>
      <div className="checkboxGrid">
        {options.map((option, index) =>
          <div key={index} className="checkboxes">
            <input
              type="checkbox"
              id={option}
              name={option}
              value={option}
              checked={selectedOptions.includes(option)}
              onChange={handleOptionChange}
            />
            <label htmlFor={option}>{option}</label>
            {option.includes("please specify") && selectedOptions.includes(option) &&
              <input
                type="text"
                name={option}
                onChange={handleTextInputChange}
                placeholder={`Specify ${option}`}
              />}
          </div>
        )}
      </div>
    </div>
  );
};

export default CheckBoxGroup;