import GoogleLoginButton from "../GoogleLoginButton/GoogleLoginButton.js";

function Login() {
  return (
    <div>
      <h1>Login</h1>
      <GoogleLoginButton/>
    </div>
  );
}

export default Login;