import './Adopt.css'
import Viewpdf from '../Viewpdf/Viewpdf';
import adopt_form from '../../assets/pdfs/12InchTails_Adoption_Application.pdf';
import adopt_trial_form from '../../assets/pdfs/12InchTails_Trial_Adoption_Agreement.pdf';
import logo from '../../assets/images/transparent_logo.png';


function Adopt() {

  return (
    <>
    <div className='adopt-bgimg'>
    <div className='adopt-div'>
      <div className='content-wrapper'>
            <h1>Adopt</h1> 
            <p>
            Ready to level up your life with a new fur-ever sidekick? Before you can join the elite ranks of pet parenthood, we've got a little mission for you: the 
            <i> <b>adoption application</b></i> <strong>(click below)</strong>. 
            It's not your average, run-of-the-mill paperwork; it's your first step to becoming a superhero for a four-legged friend in need!
            </p>
            <p>
            <Viewpdf filename={adopt_form} />
            </p>
            <p>
            If you're not sure you're quite ready for the next level yet but want to try, look at our 
            <i><b>trial adoption application</b></i>  <strong>(click below)</strong>. 
            </p>
            <p>
            <Viewpdf filename={adopt_trial_form} />
            </p>
            {/* <p>
              We recommend viewing the form then if you think it's something you want to do, then 
              <a className="all-a" href='/contact'> contact </a> us and we'll follow up with info on which furbabies are available.
            </p> */}
            {/* <p>
            Here's the scoop on how to ace our application and prove you're ready to join the league of extraordinary pet parents:
            </p>
            <p>
            Pre-Game Warm-Up: Brush up on our sneak peek questions to make sure you're all in. This isn't just about adding a cute critter to your collection; it's about ensuring you're ready for the rollercoaster of pet parenting.
            </p>
            <p>
            The Main Event: Dive into our 
            adoption application
            , available for your convenience online (for those who prefer to lounge in their PJs) or in person (for the social butterflies).
            </p>
            <p>
            The Interview: One of our friendly volunteers will have a chat with you. Think of it as a casual coffee date, but instead of discussing the weather, you'll dive deep into your daily life, pet history, and what kind of feline friend could slide into your lifestyle like they were always meant to be there.
            </p>
            <p>
            To fast-track your way to pet parenthood, here are a few pro tips:
            </p>
            <ul>
              <li>Know your living situation like the back of your hand, especially any pet policies.</li>
              <li>Have your vet on speed dial, ready to vouch for your pet parenting prowess.</li>
              <li>Line up two personal cheerleaders (aka references) who can sing your praises.</li>
              <li>After you've officially been crowned a pet parent, we'll check in to make sure your new life companion is settling in nicely. Think of us as your personal pet concierge, here to ensure everything is purring along smoothly.</li>
            </ul>
            <p>
            After you've officially been crowned a pet parent, we'll check in to make sure your new life companion is settling in nicely. Think of us as your personal pet concierge, here to ensure everything is purring along smoothly.
            </p>
            <p>
            Now, let's talk about what we might ask you:
            </p>
            <ul>
              <li>How will your current or future pets react to a new boss in town?</li>
              <li>Is your pad ready for some feline flair?</li>
              <li>Can you juggle your social life, work, and a cat's needs?</li>
              <li>Got any vacation or work travel plans? Who's the cat's plus-one?</li>
              <li>How's the home team feeling about a new mascot?</li>
              <li>Ready to deal with the glamorous side of pet ownership (fur, dirt, and the occasional surprise)?</li>
              <li>Any health issues in the household that a cat might stir up?</li>
              <li>What's the vibe at home? Cats are like furry mood rings, after all.</li>
              <li>Who's the designated cat caretaker in your crew?</li>
            </ul>
            <p>
            Other things to ponder:

            </p>
            <ul>
                <li>Kitten or adult cat? Consider your lifestyle and what age cat would be the MVP.</li>
                <li>Got the skills to deal with a cat with a quirky personality, or are you looking for more of a chill companion?</li>
                <li>Need a shadow or someone who's cool doing their own thing?</li>
                <li>Thinking about the financial side of things? Adoption fees are just the start. Don't forget about food, litter, vet visits, and, of course, toys and treats to keep your cat entertained.</li>
                <li>Time is of the essence! Cats may seem low-maintenance, but they need your time and attention, from playtime to grooming sessions.</li>
            </ul> */}
            <p>
            If you're thinking, "I've got this," then what are you waiting for? 
            <a className="all-a" href='/contact'> Contact</a> us to start your pet parenting journey!
            </p>
            <p>
            <img src={logo} style={{ width: '300px', height: '300px'}} alt="logo" />
            </p>
        </div>
      </div>
    </div>
    </>
  )
}

export default Adopt;