import { React, useState, useEffect, useRef } from 'react';
import Get from '../Get/Get';
import './Navbar.css'

const Navbar = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);

    const hamburgerRef = useRef();
    const navRef = useRef();

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        document.addEventListener('mousedown', handleClickOutside);
    
        return () => {
            window.removeEventListener("resize", handleResize);
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isHamburgerOpen]);
    
    const handleClickOutside = (event) => {
        if (hamburgerRef.current && !hamburgerRef.current.contains(event.target) 
            && navRef.current && !navRef.current.contains(event.target)) {
            setIsHamburgerOpen(false);
        }
    };

    const handleResize = () => {
        setIsMobile(window.innerWidth < 768);
    };

    const toggleHamburger = () => {
        setIsHamburgerOpen(!isHamburgerOpen);
    };

    const [user, setUser] = useState(null);
    const [userData, setUserData] = useState(null);
    
    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        if(storedUser) {
            setUser(JSON.parse(storedUser));
        }
    }, []); // empty dependency array makes this run once on component mount
    
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                // Check that user and user.email is defined
                if(user && user.email){
                    const userdata = await Get('https://azad20j2uc.execute-api.us-east-1.amazonaws.com/prod/dynamo?table_name=user-table&id_name=username&id=' + user.email);
                    setUserData(userdata);
                    // console.log(userdata);
                }
            } catch (error) {
                console.error('Error fetching user:', error);
            }
        }
    
        fetchUserData();
    }, [user]); // dependency on user state makes this run whenever user changes
    return (
        <nav>
        {isMobile && <button ref={hamburgerRef} onClick={toggleHamburger} className='hamburger'>&#9776;</button>}            
        <ul ref={navRef} className={(isMobile && isHamburgerOpen) ? 'navbar-mobile' : ''} 
                style={{ display: (isMobile && !isHamburgerOpen) ? 'none' : '' }}>
                <li>
                    <a href="/">Home</a>
                </li>
                {
                    userData && userData.role === 'admin' && (
                        <li>
                            <a href="/intake">Intake</a>
                        </li>
                    )
                }
                {!isMobile && (
                    <li>
                        <a href="/#about">About Us</a>
                    </li>
                )}
                {!isMobile && (
                <li>
                    <a href="/services">Services</a>
                </li>
                )}
                {!isMobile && (!user || (userData && userData.role !== 'admin')) && (
                    <li className="dropdown">
                        <a href="/waystogive" className="dropbtn">Ways to Give</a>
                        <div className="dropdown-content">
                            {/* <a href="https://buy.stripe.com/4gwbMac0t23M9DaaEF" target="_blank" rel="noopener noreferrer">Donate</a> */}
                            <a href="/waystogive">Donate</a>
                            <a href="https://www.amazon.com/hz/wishlist/ls/1AF2KL6LHTS3V?ref_=wl_share" target="_blank" rel="noopener noreferrer">Wishlist</a>
                        </div>
                    </li>
                )}
                <li className='dropdown-link'>
                    <a href="/waystogive">Donate</a>
                </li>
                
                <li className='dropdown-link'>
                    <a href="/adopt">Adopt</a>
                </li>
                <li className='dropdown-link'>
                    <a href="/foster">Foster</a>
                </li>
                <li className='dropdown-link'>
                    <a href="https://www.amazon.com/hz/wishlist/ls/1AF2KL6LHTS3V?ref_=wl_share" target="_blank" rel="noopener noreferrer">Wishlist</a>
                </li>
                {/* <li>
                    <a href="/shop">Shop</a>
                </li> */}
                <li>
                    <a href="/faq">FA<span className="q" style={{fontSize: '17px'}}>Q</span></a>
                </li>
                {
                    (!user || (userData && userData.role !== 'admin')) && (
                        <li>
                            <a href="/contact">Contact Us</a>
                        </li>
                    )
                }
                
                
                {/* {user ? (
                  <li className="float-right">
                    <a href="/login">{(userData && userData.name) ? userData.name : user.email}</a>
                  </li>
                ) : (
                  <li className="float-right signup">
                    <a href="/login">Sign Up/Login</a>
                  </li>
                )}           */}
            </ul>
        </nav>
    );
};

export default Navbar;