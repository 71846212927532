import React, { useState } from 'react';
import './DropDownMenu.css';

const DropDownMenu = ({ label, options, required }) => {
  const [choice, setChoice] = useState('');

  const handleChoiceChange = (event) => {
    setChoice(event.target.value);
  };

  return (
    <div>
      <label>{label}</label>
      <select value={choice} onChange={handleChoiceChange} required={required}>
        {options.map((option, index) =>
            <option key={index} value={option}>{option}</option>
        )}
      </select>
      <div>
        {choice === "Other" && <input type="text"/>}
      </div>
      <div className="RequiredDropDown">
        {(required && choice === "") && <span>*This field is required.</span>}
      </div>
    </div>
  );
};

export default DropDownMenu;